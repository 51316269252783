.graph-comp {
  height: calc(100% - 10px);
  border-radius: $border-radius;

  .title {
    font-size: $font-size-lg;
    font-weight: $font-weight-xtra-bold;
    color: $grey-color;
    margin: 0;
  }

  // .wrapper {
  //   height: calc(100% - 20px);
  // }
}

.alsfrs {
  @media screen and (min-width: 1024px) {
    .viewing-dropdown-options {
      max-width: 50%;
    }
  }
}


.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .icon {
    color: $tertiary-grey;
    transition: 0.3s all ease;
    cursor: pointer;
    width: 20px;
    margin-left: 10px;
  }

  .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #f0f0f0;
    color: #333;
    text-align: left;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -150px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
}