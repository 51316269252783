.signin-page {
  min-height: 100vh;
  @supports (background-image: url("../../../images/Image.webp")) {
    background-image: linear-gradient(to left, rgba(245, 246, 252, 0.52), $primary-blue), url("../../../images/Image.webp");
  }
  @supports not (background-image: url("../../../images/Image.webp")) {
    background-image: linear-gradient(to left, rgba(245, 246, 252, 0.52), $primary-blue), url("../../../images/Image.jpg");
  }

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .sign-in-section {
    .uk-card {
      background-color: transparent;
      box-shadow: none;

      .uk-card-body {
        .sign-in-title {
          color: $white-color;
          font-weight: 800;
          text-transform: uppercase;
          // font-size: $font-size-xxxl;
        }

        .sign-in-text {
          color: $white-color;
          font-size: 16px;
          text-align: justify;
        }
      }

      .footer {
        padding: 20px;

        .text {
          color: $white-color;
          font-size: 10px;
          cursor: pointer;
          margin: auto 10px;
        }
      }
    }
  }

  .logo {
    width: 120px;
  }

  .page-title {
    text-transform: capitalize;
    margin-bottom: 2rem;
    font-weight: $font-weight-bold;
    font-size: $font-size-md;
  }

  .signin-card {
    border-radius: $border-radius-lg;
    justify-content: center;
  }
}

.errorpage {
  @supports (background-image: url("../../../images/Image.webp")) {
    background: linear-gradient(to left, rgba(245, 246, 252, 0.52), #016caa), url("../../../images/Image.webp") !important;
  }
  @supports not (background-image: url("../../../images/Image.webp")) {
    background: linear-gradient(to left, rgba(245, 246, 252, 0.52), #016caa), url("../../../images/Image.jpg") !important;
  }

  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}
