.drawer-content {
  width: $drawer-width;
  background: $primary-blue;
  overflow: auto;

  .drawer-list {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .main-list {
      list-style: none;
      margin: 0;

      .list-item {
        &.uk-parent.uk-open {
          .down-arrow {
            position: absolute;
            right: 5px;
            top: 8px;
            transform: rotate(180deg);
          }
        }

        .navlink {
          position: relative;
          font-size: 12px;
          transition: all 0.3s ease-in;
          color: $white-color;
          font-weight: $font-weight-semibold;
          text-transform: uppercase;
          border-bottom: .5px solid $white-color;
          line-height: 35px;

          .icon {
            transition: all 0.3s ease-in;
            width: 28px;
            padding: 2px;
            margin-left: 5px;
            border-radius: 50%;
          }

          .down-arrow {
            position: absolute;
            right: 5px;
            top: 8px;
            padding: 5px 0px;
            transition: transform 0.3s ease-in-out;
          }

          &:hover {
            background-color: $secondary-blue;
            transform: translateY(-02px);

            .icon {
              background-color: $white-color;
            }
          }
        }

        span.navlink {
          padding: 5px 0;
          display: block;
          text-decoration: none;
          cursor: pointer;
        }

        .active {
          background-color: $secondary-blue;
          color: $white-color;

          .icon {
            background-color: $white-color;
          }
        }

        .uk-nav-sub {
          padding: 0px 0 4px;

          .navlink {
            padding-left: 40px;
            font-size: 10px;

            .sub-icon {
              position: absolute;
              right: 5px;
              top: 8px;
              transition: transform 0.3s ease-in-out;
            }
          }

          .active {
            border-left: 4px solid $white-color;
          }
        }
      }
    }

    .nav-footer {
      .list-item {
        margin-bottom: 4px;
        list-style: none;

        .footer-link {
          padding: 0.2rem .9rem;
          font-size: $font-size-xs;
          transition: 0.3s ease-in-out all;
          color: $white-color;
          font-weight: $font-weight-semibold;
          text-transform: uppercase;
          border-bottom: .5px solid $white-color;
          background-color: $primary-blue;

          &:hover {
            cursor: pointer;
            background-color: $hover-color;
          }
        }
      }
    }
  }
}

.uk-offcanvas-bar {
  background: $primary-blue;
  padding: 10px;
  // min-width: 260px;
  overflow: auto;

  .uk-offcanvas-close {
    border: 3px solid $white-color;
    border-radius: 50%;
    // color: #000;
  }

  .drawer-content {
    border-radius: 5px;
  }

  .avartar-username {
    .name {
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
      color: $white-color;
      font-size: $font-size-md;
    }
  }


  .account-settings {
    margin: 5px;

    img {
      object-fit: contain;
      -o-object-fit: contain;
      object-position: center center;
      -o-object-position: center center;
      width: 120px;
    }
  }
}