.custom-modal-style {
  .uk-modal-body {
    border-radius: $border-radius;
    padding: 30px 8px;

    .uk-modal-title {
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      color: var(--color-text);
    }

    .sv-title-div {
      background-color: $primary-blue;
      padding: 2px;
      border-radius: $border-radius;
      box-shadow: 0 5px 15px rgba(0, 0, 0, .08);

      p {
        color: $white-color;
        font-weight: $font-weight-semibold;

      }

      .sv-description {
        font-style: italic;
        background-color: rgb(216, 216, 216);
        padding: 6px;
        text-align: justify;
        word-break: keep-all;
      }
    }

    .sv-question {
      box-shadow: 0 5px 15px rgba(0, 0, 0, .08);
      border: 2px solid $primary-blue;
      border-radius: $border-radius;
    }

    .sv-item-header {
      background-color: $primary-blue;
      box-shadow: 0 5px 15px rgba(0, 0, 0, .08);

      .sv-item-no {
        color: $white-color;
        font-weight: $font-weight-semibold;
        padding-right: 5px;
      }

      .item-header-label {
        color: $white-color;
        font-weight: $font-weight-semibold;

        small {
          font-style: italic;
          font-size: 72% !important;
        }
      }
    }

    .sv-input-comp {
      padding: 5px;
      margin: 4px;

      .comp-label {
        font-size: 15.4px;

        .comp-description {
          font-style: italic;
          line-height: 15px;
        }
      }

      &:hover {
        background-color: rgb(243, 243, 243);
      }
    }

    .text {
      font-size: 80%;
      text-align: justify;
    }

    .results {
      padding: 10px;
      background-color: $primary-blue;
      border-radius: $border-radius;

      .date {
        color: $white-color;
        margin: 0;
      }

      .desc {
        margin: 0;
        font-size: $font-size;
      }

      .result {
        background-color: #FFF6F5;
        border-radius: $border-radius;
        margin: 28px auto 15px;
        display: flex;
        justify-content: space-between;
        padding: 4px;

        .title {
          color: $primary-blue;
          font-weight: $font-weight-semibold;
          margin: 0;
        }

        .score {
          font-size: 15px;
          margin: 0;
        }
      }
    }
  }

  .read-attachment-modal {
    min-height: 100vh;

    .title {
      margin: 0;
      padding: 0px 20px;
      line-height: 40px;
    }

    .close {
      padding: 0px 20px;
      line-height: 40px;
    }

    iframe {
      height: calc(100vh - 40px);
      width: 100%;
    }
  }
}