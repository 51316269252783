ul.adb-tabs {
  margin: 0;

  &::before {
    content: none;
  }

  li {

    margin: 0;
    padding: 0;

    &:not(:first-child) {
      margin-left: 5px;
    }

    &.uk-active {
      a {
        color: $white-color;
        background-color: $primary-btn;
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
      }
    }

    a {
      margin-top: 10px;
      font-size: $font-size-sm;
      font-weight: $font-weight-semibold;
      background-color: $tertiary-grey;
      color: #999;
      text-transform: capitalize;
      border: 0;
      transition: 0.3s all ease;
      border-radius: $btn-border-radius;
      width: max-content;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        box-shadow: 0 10px 14px rgba($primary-btn, 0.4);
      }
    }
  }
}