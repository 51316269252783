.application-layout {
  display: flex;

  .drawer-layout {
    position: fixed;
    height: 100%;
    background: $primary-blue;
  }

  .main-layout {
    width: 100%;

    @media only screen and (min-width: 640px) {
      margin-left: $drawer-width;
    }

    @media only screen and (max-width: 960px){
      margin-left: 0;
    }
  }
}