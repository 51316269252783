@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import "./variables.scss";
@import "./loggedout/loggedOut.scss";
@import "./shared/index.scss";

@import "./loggedin/Drawer.scss";
@import"./loggedin/MainLayout.scss";
@import "./loggedin/Navbar.scss";
@import "./loggedin/Profile.scss";
@import "./loggedin/BarGraph.scss";


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;

}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    background: #fafdfa;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

.animated-text {
    font-size: 1em;
    font-weight: bold;
    color: $primary-blue;
    animation: fadeInOut 2s infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}