.sv-uk-card {
    border-radius: $border-radius;
    transition: all 0.5s ease-in-out;
    border: 1px solid transparent;

    .icon {
        color: $primary-blue;
        padding-bottom: 5px;
    }

    h5 {
        color: $primary-grey;
        font-size: $font-size-md;
        margin-bottom: 2px;
    }

    p {
        margin: 2px;
        font-size: $font-size-sm;
    }

    &.prima {
        background-color: $primary-blue;
    }

    &.second {
        background-color: $primary-btn;
    }

    &.tertia {
        background-color: $grey-color;
    }

    &:hover {
        border: 1px solid $primary-blue;
        transform: translateY(-02px);

        .icon {
            color: $white-color;
            cursor: pointer;
        }
    }
}

.dash-uk-card {
    border-radius: $border-radius;
    transition: all 0.3s ease-in;


    h5,
    p {
        color: $white-color;
    }

    &.prima {
        background-color: $primary-blue;
    }

    &.second {
        background-color: $primary-btn;
    }

    &.tertia {
        background-color: $grey-color;
    }

    &:hover {
        background-color: $secondary-blue;
        transform: translateY(-02px);
    }
}

.empty {
    display: flex;
    justify-content: center;

    .text {
        text-transform: uppercase;
        color: #46addd;
        font-weight: 500;
        font-size: 0.75rem;
        margin-left: 5px;
    }
}