.btn {
  padding: $btn-padding-sm;
  border-radius: $btn-border-radius;
  margin: 10px 2px;
  color: $white-color;
  font-weight: $font-weight-semibold;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border: none;
  font-size: $font-size-sm;

  &.btn-primary {
    background-color: $primary-btn;

    &:hover {
      box-shadow: 0 6px 8px rgba($primary-btn, 0.4);
    }

    &.active {
      background-color: $warning-color;
    }
  }

  &.btn-warning {
    background-color: $warning-color;

    &:hover {
      box-shadow: 0 10px 14px rgba($warning-color, 0.4);
    }
  }

  &.btn-danger {
    background-color: $danger-color;

    &:hover {
      box-shadow: 0 10px 14px rgba($danger-color, 0.4);
    }
  }

  &:disabled {
    opacity: 0.5;
    background-color: $grey-color;
    cursor: not-allowed;

    &:hover {
      box-shadow: 0 10px 14px rgba($grey-color, 0.4);
    }
  }

  &.link {
    &:hover {
      text-decoration: none;
      color: $white-color;
    }
  }
}

.btn-text {
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;

  &.btn-primary {
    background-color: $primary-btn;
  }

  &.btn-secondary {
    color: $white-color;
  }

  &.btn-disabled {
    color: $grey-color;
    cursor: not-allowed;
  }

  &.btn-danger {
    background-color: $danger-color;
  }

  &:hover {
    text-decoration: none;
  }
}

.btn-icon {
  padding: $btn-padding-sm;
  border-radius: $btn-border-radius;
  margin: 0 2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: $white-color;
  border: none;

  &.btn-disabled {
    color: $grey-color;
    cursor: not-allowed;
  }

  &.btn-primary {
    background-color: $primary-btn;

    &:hover {
      box-shadow: 0 10px 14px rgba($primary-btn, 0.4);
    }
  }

  &.btn-danger {
    background-color: $danger-color;

    &:hover {
      box-shadow: 0 10px 14px rgba($danger-color, 0.4);
    }
  }
}

.kit-btn-back {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: $secondary-btn;
  line-height: 1;
  margin: 0;

  span {
    transition: 0.3s all ease-in-out;
  }

  &:hover {
    cursor: pointer;

    span {
      margin-right: 5px;
    }
  }
}