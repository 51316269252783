.lg-navbar {
  background-color: $white-color;
  height: 80px;
  padding: 0 15px;
  border-bottom: 2px solid $primary-blue;

  .account-settings {
    padding: 5px 10px;
    width: 100px;

    img {
      object-fit: contain;
      -o-object-fit: contain;
      object-position: center center;
      -o-object-position: center center;
      width: 120px;
    }
  }

  .navbar-title {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    font-weight: $font-weight-semibold;
    white-space: nowrap;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    .icon {
      transition: all 0.3s ease-in-out;

      span {
        width: 30px;
      }
    }

    p {
      font-size: $font-size-md;
      width: clamp(calc(100% - 30px), 300px, 100%);
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    &:hover {
      cursor: pointer;

      .icon {
        margin-right: 5px;
      }
    }

  }

  .display-language {
    font-weight: 500;
    text-transform: capitalize;
    color: #016caa;
    font-size: 0.75rem
  }

  .avartar-li-item {
    display: flex;
    align-items: center;

    .avartar-username {
      margin: 0 10px 0 20px;

      .name {
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
        color: $primary-blue;
        font-size: $font-size-xs;
      }
    }

    .user-avartar {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      background-color: $white-color;
      border: 3px solid $primary-blue;
      cursor: pointer;

    }
  }

  .uk-navbar-toggle {
    padding: 12px;
    transition: all 0.3s ease-in;
    min-height: 50px;

    &:hover {
      background-color: $secondary-blue;

      svg {
        color: $white-color;

      }
    }
  }


  .notification-box {
    height: 42px;
    width: 42px;
    text-align: center;
    background-color: $primary-blue;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
  }

  .notification-bell * {
    display: block;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 0px 15px #fff;
  }

  .bell-top {
    width: 6px;
    height: 6px;
    border-radius: 3px 3px 0 0;
  }

  .bell-middle {
    width: 22px;
    height: 20px;
    margin-top: -1px;
    border-radius: 12.5px 12.5px 0 0;
  }

  .bell-bottom {
    position: relative;
    z-index: 0;
    width: 30px;
    height: 2px;
  }

  .bell-bottom::before,
  .bell-bottom::after {
    content: '';
    position: absolute;
    top: -4px;
  }

  .bell-bottom::before {
    left: 1px;
    border-bottom: 4px solid #fff;
    border-right: 0 solid transparent;
    border-left: 4px solid transparent;
  }

  .bell-bottom::after {
    right: 1px;
    border-bottom: 4px solid #fff;
    border-right: 4px solid transparent;
    border-left: 0 solid transparent;
  }

  .bell-rad {
    width: 8px;
    height: 4px;
    margin-top: 2px;
    border-radius: 0 0 4px 4px;
  }

  .notification-count {
    position: absolute;
    z-index: 1;
    top: 3px;
    width: 22px;
    height: 22px;
    // font-size: 18px;
    border-radius: 50%;
    background-color: #ff4927;
    color: #fff;
  }

}

.sm-navbar {
  display: grid;
  justify-content: center;
  text-align: center;
  align-content: center;
  background: white;
  padding: 10px 0;
}