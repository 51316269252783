.profile {
  .profile-title {
    text-transform: uppercase;
    color: $primary-blue;
    font-weight: $font-weight-semibold;
    font-size: $font-size-md;
    margin-bottom: 3px;
  }

  .sub-title {
    text-transform: uppercase;
    font-size: $font-size-lg;
    color: $secondary-grey;
    font-weight: $font-weight-semibold;
    margin-top: 4px;
  }

  .text {
    font-size: 14px;
    text-align: justify;
    word-break: keep-all;
  }

  .sticky-top {
    top: 60px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 100;

    .toolbar {
      margin-top: 10px;
    }
  }

  .uk-card {
    border-radius: $border-radius;

    .profile-card-title {
      text-transform: uppercase;
      font-weight: $font-weight-semibold;
      font-size: $font-size-md;
      color: $primary-blue;
    }

    .center {
      text-align: center;
      display: flex;
      justify-content: center;
      border-radius: $border-radius $border-radius 2px 2px;
      background-color: $primary-blue;

      .profile-icon {
        justify-content: center;
        align-items: center;
        height: 55px;
        width: 55px;
        border-radius: 50%;
        background-color: $primary-blue;
        color: $white-color;
        display: flex;
      }
    }

    .profile-card-hearder {
      border-radius: $border-radius $border-radius 2px 2px;
      background-color: $primary-blue;

      h5 {
        color: $white-color;
        margin: 0;
      }
    }

    .download-icon {
      width: 23px;
      cursor: pointer;
    }

    .view-icon {
      cursor: pointer;
    }
  }

  .results {
    padding: 10px;
    border-radius: $border-radius;
    transition: all 0.5s ease-in-out;
    border: 1px solid transparent;

    .date {
      color: $primary-blue;
      margin: 0;
      font-size: 13px;
    }

    .desc {
      margin: 0;
      font-size: $font-size;
    }

    .result {
      background-color: $tertiary-grey;
      border-radius: $border-radius;
      margin: 28px auto 15px;
      display: flex;
      justify-content: space-between;
      padding: 4px;

      .title {
        color: $primary-blue;
        font-weight: $font-weight-semibold;
        margin: 0;
        font-size: $font-size;
      }

      .score {
        font-size: 15px;
        margin: 0;
        font-size: $font-size;
      }
    }

    &:hover {
      transform: translateY(-02px);
      border: 1px solid $primary-blue;
    }
  }

  .progression-title {
    color: $primary-grey;
  }

  .progression {
    background-color: #eef6fb;
    box-shadow: none;
    height: 85px;
    min-height: 85px;
    border: 1px solid transparent;
    transition: all 0.5s ease-in-out;

    .rate-card {
      text-align: center;
      margin: 5px 10px;
      border: 1px solid transparent;

      .rates {
        display: flex;
        justify-content: center;

        .rate {
          background-color: $secondary-blue;
          color: #fff;
          padding: 12px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
        }
      }

      .rate-text {
        color: $primary-grey;
        margin-top: 0;
        font-size: $font-size-xs;
        text-transform: capitalize;
        margin-bottom: 3px;
      }
    }

    &:hover {
      border: 1px solid $primary-blue;
      transform: translateY(-02px);
      border-radius: $border-radius;
    }
  }
}
