.adb-dropdown {
  padding: 1em;
  border-radius: $border-radius;
  width: max-content !important;

  .adb-dropdown-nav {
    padding: 0;

    li {
      margin: 0;

      a {
        text-decoration: none;
        color: $primary-blue;
        margin: 8px 0px;
        font-size: 90%;

        &:hover {
          text-decoration: none
        }
      }

      .text-link {
        color: $primary-blue;
        margin: 8px 0px 0px 0px;
        font-size: 90%;
      }
    }
  }
}