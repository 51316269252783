$primary-blue: #016caa;
$secondary-blue: #2c9cd2;
$tertiary-blue: #b8e2f6;

$primary-grey: #6d6e71;
$secondary-grey: #a7a9ac;
$tertiary-grey: #e6e7e8;


$white-color: #ffff;
$black-color: #000;
$grey-color: rgb(177, 179, 182);
$dark-grey-color: #484b44;
$hover-color: #4fafdb;
$muted-color: #f0f0f0;

$success-color: #4bb543;
$danger-color: #dc3545;
$warning-color: #faa05a;

//fonts
$font-size-xs: 0.65rem;
$font-size-sm: 0.75rem;
$font-size: 12.5px;
$font-size-md: 0.8rem;
$font-size-lg: 1.2rem;
$font-size-xl: 1.4rem;
$font-size-xxl: 1.6rem;
$font-size-xxxl: 2rem;

$font-weight: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-xtra-bold: 700;

// Border radius
$border-radius: 3px;
$border-radius-lg: 8px;

// Button colors
$primary-btn: #1768b9;
$secondary-btn: #2f80ed;
$secondary-btn-hover: #35508b;
$danger-btn: #e41f25;
$danger-btn-hover: #de2227;


// button padding
$btn-padding: 5px 25px;
$btn-padding-sm: 5px 15px;
$btn-border-radius: 3px;

$letter-spacing: 2px;

// Drawer size
$drawer-width: 240px;