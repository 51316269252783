.reads {
    .uk-card {
        border-radius: $border-radius;
    }

    .app-image {
        .logo {
            max-width: 90px;
            height: auto;
        }
    }

    .text {
        font-size: .875rem;
        line-height: 1.5;
    }
}

.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;


    .title {
        font-size: 1.5rem;
        font-weight: 500;
        color: #000;
    }

    .controls {
        display: flex;

        @media screen and (max-width: 430px) {
            display: flex;
            justify-content: space-between;

            &.mg-top {
                margin-top: 5px;
            }
        }
    }
}


.error-boundary {
    .title {
        display: inline;
        font-size: $font-size-lg;
    }

    button {
        display: inline;
        color: $primary-blue;
        font-size: $font-size-md;
        font-weight: $font-weight-semibold;
        padding: 5px 10px;
        margin-left: 20px;
        transition: 0.3s all ease;

        &:hover {
            background-color: $primary-blue;
            color: $white-color;
        }
    }
}

.adb-made-accordion {
    border-radius: 3px;
    background: $white-color;
    box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
    padding: 15px 8px;
    margin: 0px 2px 4px 2px;
    border: 2px solid #fff;
    transition: border 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        border: 2px solid $primary-blue;
        cursor: pointer;
    }

    .position-relative {
        position: relative;
        cursor: pointer;

        .adb-title {
            color: #666;
            margin: 0;
            font-weight: 600;
            font-size: $font-size-md;
        }

        .more {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);

            .icon {
                color: $white-color;
                transition: all 0.3s ease-in-out;
                background-color: $grey-color;
                border-radius: 50%;
                padding: 2px;

                &.expanded {
                    transform: rotate(180deg);
                    background-color: $primary-blue;
                }
            }
        }

    }

    .adb-accordion {
        max-height: 0;
        overflow: hidden;
        transition: all 0.25s ease-in-out;
        overflow-y: auto;

        &.expanded {
            max-height: 600px;
        }
    }
}

.field-info {
    display: inline-block;
    position: relative;

    .icon {
        color: $tertiary-grey;
        transition: 0.3s all ease;
        cursor: pointer;
        width: 20px;
        margin-left: 10px;
    }

    &.active {
        .description {
            display: block;
        }
    }

    .description {
        &::before {
            content: "";
            display: block;
            font-size: $font-size-xs;
            font-weight: $font-weight-semibold;
            color: $dark-grey-color;
            text-transform: uppercase;
        }

        display: none;
        position: absolute;
        z-index: 1;
        top: 100%;
        margin: 0;
        font-size: $font-size-sm;
        background-color: $hover-color;
        color: $dark-grey-color;
        padding: 10px;
        width: clamp(100px, 50vmin, 350px);
        box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.1);
        pointer-events: none;
        border-radius: 4px;
        text-align: justify;
    }

    &.align-center .description {
        left: 50%;
        transform: translateX(-100%);
        min-width: 320px;
    }

    &.align-right .description {
        right: 0%;
    }
}

// overrides

.uk-input,
.uk-textarea,
.uk-select {
    border-radius: $border-radius !important;
}

.uk-tooltip {
    background-color: $primary-blue;
}

.uk-dropdown {
    border-radius: $border-radius !important;
}

input[type=range] {
    height: 34px;
    margin: 10px 0;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    background: #74A9D8 !important;
    border-radius: $border-radius;
}

input[type=range]::-webkit-slider-thumb {
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #74A9D8;
}



.dashboard {
    .main-title {
        color: $primary-blue;
        font-size: $font-size-lg;
    }
}

.progression {
    box-shadow: none;
    height: 85px;

    .slow {
        background-color: #eef6fb;
    }

    .average {
        background-color: #e3f1f8;
    }

    .fast {
        background-color: #dbecf7;
    }

    .slow,
    .average,
    .fast {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border: 1px solid transparent;
        transition: all 0.5s ease-in-out;

        .rates {
            display: flex;
            justify-content: center;

            .rate {
                background-color: $secondary-blue;
                color: #fff;
                padding: 12px;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
            }
        }

        .rate-text {
            color: $primary-blue;
            margin-top: 0;
            font-size: $font-size-xs;
            text-transform: uppercase;
            margin-bottom: 3px;
        }

        &:hover {
            border: 1px solid $primary-blue;
            transform: translateY(-02px);
            border-radius: $border-radius;
        }
    }
}

.custombg {
    background-color: #eef6fb;
    padding: 5px;
}